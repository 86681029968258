import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { createOrder, getActiveOrderList } from "../Config/ConfigServices";
import TablePagination from "../TablePagination";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { Modal, Button, Form, Col, Row } from "react-bootstrap";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { addAdminData } from "../../../redux/User/action";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { useFormik } from "formik";
import * as Yup from "yup";
import { showErrorToast, showSuccessToast } from "./ToastMsg";
import { headerSortingClasses } from "../../../_metronic/_helpers";
import moment from "moment/moment";
import { charValidate } from "./utility/function";
import Tooltip from 'react-tooltip-lite';


const SignUpSchema = Yup.object({
  email: Yup.string().email().trim().required("Email field can't be empty"),
  phoneNo: Yup.string().min(10, "Phone no. must be at least 10 characters").trim().required("Phone No. can't be empty"),
  alignOrderNumber: Yup.string().trim().required("Align order no. is required"),
  sku: Yup.string().trim().required("SKU id is required"),
  quantity: Yup.number().integer("Quantity must be an integer").min(0, "Quantity must be a non-negative integer").required("Quantity is required"),
  description: Yup.string().trim().required("Description is required"),
  backImgUrl: Yup.string().trim().required("Image url is required"),
  shippName: Yup.string().trim().required("Shipping name is required"),
  street1: Yup.string().trim().required("street is required"),
  street2: Yup.string().trim(),
  shippcountry: Yup.string().trim().required("Country is required"),
  shippState: Yup.string().trim().required("State is required"),
  shippCity: Yup.string().trim().required("City is required"),
  shippZipCode: Yup.number().required("Zip code is required"),
  websiteUrl: Yup.string().matches(/^(?:(?:https?|http?|ftp?):\/\/)?(?:www\.)?[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}(?:\/\S*)?$/, "Invalid URL format").trim().required("Web url is required"),
});

const initialValues = {
  email: "",
  phoneNo: "",
  sku: "",
  quantity: "",
  description: "",
  backImgUrl: "",
  shippName: "",
  street1: "",
  street2: "",
  shippcountry: "",
  shippState: "",
  shippCity: "",
  shippZipCode: "",
  websiteUrl: "",
  alignOrderNumber: "",
};

export default function ActiveOrder() {
  const history = useHistory();
  const location = useLocation();
  const [arrayData, setArrayData] = useState([]);
  const [orderStatus, setOrderStatus] = useState("");
  const dispatch = useDispatch();
  const d = new Date();
  let seconds = d.getSeconds();
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const handleOpenModal = () => {
    setShow(true);
  };
  const handleCloseModal = () => {
    setShow(false);
  };

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    errors,
    resetForm,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: SignUpSchema,
    onSubmit: (values, action) => {
      const backendImgUrlArray = values.backImgUrl.split(",");
      let obj =
      {
        // "id": 0,
        "shippingAddress": {
          // "id": 0,
          "name": values.shippName,
          "street1": values.street1,
          "street2": values.street2,
          "country": values.shippcountry,
          "state": values.shippState,
          "city": values.shippCity,
          "zipCode": values.shippZipCode + "",
        },
        "phoneNumber": values.phoneNo,
        "email": values.email,
        "websiteUrl" : values.websiteUrl,
        "alignOrderNumber": values.alignOrderNumber,
        // "shippingMethod": 1,
        "item": [
          {
            // "id": 0,
            "skuId": values.sku,
            "description": values.description,
            "totalQty": values.quantity,
            "images": {
              "frontImages": [],
              "backImages": backendImgUrlArray
            }
          }
        ],
        "remarks": ""
      }
      createOrder(obj)
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          dispatch(addAdminData(values));
          showSuccessToast(res?.message);
          handleCloseModal()
          action.resetForm();
          if (res.status == 400) {
            showErrorToast("Error: Bad Request");
          }
        })
        .catch((error) => {
          showErrorToast(error.message || "Validation Error");
        });
    },
  });

  const listenchange = (e) => {
    let temp = e.target.value;
    Cookies.set("filterText", temp);
    setData(temp);
    dispatch(addAdminData(seconds));
  };
  function dateAndTimeFormatter(cell, row) {
    let obj = row.createdOn
    return moment.utc(obj).format("LL");
  }

  const renderActions = (_, row) => {
    return (
      <>
        <a
          href="#"
          title="Show more info"
          className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
          onClick={(e) => {
            e.stopPropagation();
            showModal(e, row);
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/General/Settings-2.svg")}
            />
          </span>
        </a>
      </>
    );
  };

  const formateShippingMethod = (_, row) => {
    if (row.shippingMethod == "") {
      return <p style={{ marginLeft: "2rem" }}><i className="fa fa-ellipsis-h"></i></p>
    } else {
      return <p style={{ marginLeft: "8px" }}> {row.shippingMethod}</p>
    }
  }
  const formatTrackNo = (cell, row) => {
    if (row.trackNumber == null) {
      return <p style={{ marginLeft: "2rem" }}><i className="fa fa-ellipsis-h"></i></p>
    } else {
      return <p style={{ marginLeft: "8px" }}> {row.trackNumber}</p>
    }
  }
  const formatRemark = (cell, row) => {
    if (row.remarks == "" || null) {
      return <p><i className="fa fa-ellipsis-h"></i></p>
    } else {
      return (
        <Tooltip content={row.remarks} background="rgba(0, 0, 0, 0.8)" color="#fff">
           {charValidate(row.remarks, 50)}
        </Tooltip>
      );
    }
  }
  const formateWebUrl = (cell, row) => {
    if (row.remarks == null) {
      return <p><i className="fa fa-ellipsis-h"></i></p>
    } else {
      return (
        <Tooltip content={row.websiteUrl} background="rgba(0, 0, 0, 0.8)" color="#fff">
           <span style={{ marginRight: "-100px" }}>{charValidate(row.websiteUrl, 50)}</span>
        </Tooltip>
      );
    }
  }
  const alignOrderFormatter = (cell, row) => {
    if (row?.remarks === null) {
      return <p><i className="fa fa-ellipsis-h"></i></p>
    } else {
      return (
        <Tooltip content={row?.alignOrderNumber} background="rgba(0, 0, 0, 0.8)" color="#fff">
           <span >{charValidate(row?.alignOrderNumber, 50)}</span>
        </Tooltip>
      );
    }
  }

  const formatUserName = (cell, row) => {
    if(row.shippingAddress.name == null){
      return <p><i className="fa fa-ellipsis-h"></i></p>
    }else{
      let name = row?.shippingAddress?.name
      return name;
    }
  }

  const formattedOrder = (cell, row) => {
    if(row.orderStatus === "Accept"){
      return "Accepted"
    }
    return row.orderStatus;
  }

  const showModal = (e, obj) => {
    let ItemArray = obj.item;
    setArrayData(obj.item);
    e.preventDefault();
    history.push({ pathname: "/item_details", state: obj });
  };

  const columns = [
    {
      dataField: "id",
      text: "ID",
      hidden: true,
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: renderActions,
      // classes: "text-right pr-2",
      headerClasses: "text-right pr-3",
    },
    {
      dataField: "alignOrderNumber",
      text: "Align Order Number",
      formatter: alignOrderFormatter,
      headerSortingClasses,
    },
    {
      dataField: "shippingAdderss[0].createdOn",
      text: "Created Date",
      formatter: dateAndTimeFormatter,
      headerSortingClasses,
    },
    // {
    //   dataField: "orderNumber",
    //   text: "Order Number",
    //   headerSortingClasses,
    // },
    {
      dataField: "shippingAdderss[0].name",
      text: "User Name",
      formatter: formatUserName,
      headerSortingClasses,
    },
    {
      dataField: "websiteUrl",
      text: "Web URL",
      formatter: formateWebUrl,
      headerSortingClasses,
    },
    {
      dataField: "orderStatus",
      text: "Order Status",
      headerSortingClasses,
      formatter: formattedOrder,
    },
    {
      dataField: "item[0].skuId",
      text: "SKU",
      headerSortingClasses,
    },
    {
      dataField: "shippingMethod",
      text: "Shipping Method",
      formatter: formateShippingMethod,
      headerSortingClasses,
    },
    {
      dataField: "remarks",
      text: "Remark",
      formatter: formatRemark,
      headerSortingClasses,
    },
    {
      dataField: "trackNumber",
      text: "Track No.",
      formatter: formatTrackNo,
      headerSortingClasses,
    },
  ];
  const CrateOrder = () => {
    handleOpenModal()
  }

  return (
    <>
      <Card>
        <CardHeader title="All Orders">
          <CardHeaderToolbar>
            <select
              name="statusSelect"
              id="statusSelect"
              onChange={listenchange}
              style={{
                width: "120px",
                height: "30px",
                border: "2px solid #541b1b",
                fontSize: "1.250em",
              }}
            >
              <option value="" defaultValue>
                All
              </option>
              <option value="0">Pending</option>
              <option value="1">Accepted</option>
              <option value="3">Rejected</option>
              <option value="4">Shipped</option>
            </select>
            <Button className="ml-2" onClick={CrateOrder}>Create Order</Button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody style={{ justifyContent: "center" }}>
          <TablePagination
            keyField="Id"
            columns={columns}
            getRecordList={getActiveOrderList}
            isShowExportCsv={false}
          />
        </CardBody>
      </Card>
      <Modal
        show={show}
        onHide={handleCloseModal}
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Create Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <h6 className="pb-5">Contact</h6>
            <Row>
              <Col>
                <Form.Label>Enter Email <span style={{color: "red"}}>*</span></Form.Label>
                <Form.Control
                  type="text"
                  name="email"
                  autoComplete="off"
                  onChange={handleChange}
                  value={values.email}
                  onBlur={handleBlur}
                  placeholder="Enter Email"
                  className="mb-2"
                />
                {errors.email && touched.email ? (
                  <p style={{ color: "red" }}> {errors.email} </p>
                ) : null}
              </Col>
              <Col>
                <Form.Label>Phone No. <span style={{color: "red"}}>*</span></Form.Label>
                <Form.Control
                  type="text"
                  name="phoneNo"
                  autoComplete="off"
                  onChange={handleChange}
                  value={values.phoneNo}
                  onBlur={handleBlur}
                  placeholder="Enter Phone Number"
                  className="mb-2"
                />
                {errors.phoneNo && touched.phoneNo ? (
                  <p style={{ color: "red" }}> {errors.phoneNo} </p>
                ) : null}
              </Col>
            </Row>
            <hr />
            <h6 className="pb-5">Item</h6>
            <Row>
            <Col md={6}>
                <Form.Label>Align Order No. <span style={{color: "red"}}>*</span></Form.Label>
                <Form.Control
                  type="text"
                  name="alignOrderNumber"
                  autoComplete="off"
                  onChange={handleChange}
                  value={values.alignOrderNumber}
                  onBlur={handleBlur}
                  placeholder="Enter Align Order Number"
                  className="mb-2"
                />
                {errors.alignOrderNumber && touched.alignOrderNumber ? (
                  <p style={{ color: "red" }}> {errors.alignOrderNumber} </p>
                ) : null}
              </Col>
              <Col md={6}>
                <Form.Label>SKU <span style={{color: "red"}}>*</span></Form.Label>
                <Form.Control
                  type="text"
                  name="sku"
                  autoComplete="off"
                  onChange={handleChange}
                  value={values.sku}
                  onBlur={handleBlur}
                  placeholder="Enter SKU Id"
                  className="mb-2"
                />
                {errors.sku && touched.sku ? (
                  <p style={{ color: "red" }}> {errors.sku} </p>
                ) : null}
              </Col>
              <Col md={6}>
                <Form.Label>Quantity <span style={{color: "red"}}>*</span></Form.Label>
                <Form.Control
                  type="number"
                  name="quantity"
                  autoComplete="off"
                  onChange={handleChange}
                  value={values.quantity}
                  onBlur={handleBlur}
                  placeholder="Enter Quantity"
                  className="mb-2"
                />
                {errors.quantity && touched.quantity ? (
                  <p style={{ color: "red" }}> {errors.quantity} </p>
                ) : null}
              </Col>
              <Col md={6}>
                <Form.Label>Description <span style={{color: "red"}}>*</span></Form.Label>
                <Form.Control
                  type="text"
                  name="description"
                  autoComplete="off"
                  onChange={handleChange}
                  value={values.description}
                  onBlur={handleBlur}
                  placeholder="Enter Description"
                  className="mb-2"
                />
                {errors.description && touched.description ? (
                  <p style={{ color: "red" }}> {errors.description} </p>
                ) : null}
              </Col>
              <Col md={6}>
                <Form.Label>Back mage URL <span style={{color: "red"}}>*</span></Form.Label>
                <Form.Control
                  type="text"
                  name="backImgUrl"
                  autoComplete="off"
                  onChange={handleChange}
                  value={values.backImgUrl}
                  onBlur={handleBlur}
                  placeholder="Enter Image URL"
                  className="mb-2"
                />
                {errors.backImgUrl && touched.backImgUrl ? (
                  <p style={{ color: "red" }}> {errors.backImgUrl} </p>
                ) : null}
              </Col>
            </Row>
            <hr />
            <h6 className="pb-5">Shipping</h6>
            <Row>
              <Col md={6}>
                <Form.Label>Name <span style={{color: "red"}}>*</span></Form.Label>
                <Form.Control
                  type="text"
                  name="shippName"
                  autoComplete="off"
                  onChange={handleChange}
                  value={values.shippName}
                  onBlur={handleBlur}
                  placeholder="Enter Shipping Name"
                  className="mb-2"
                />
                {errors.shippName && touched.shippName ? (
                  <p style={{ color: "red" }}> {errors.shippName} </p>
                ) : null}
              </Col>
              <Col md={6}>
                <Form.Label>Street 1 <span style={{color: "red"}}>*</span></Form.Label>
                <Form.Control
                  type="text"
                  name="street1"
                  autoComplete="off"
                  onChange={handleChange}
                  value={values.street1}
                  onBlur={handleBlur}
                  placeholder="Enter Street 1"
                  className="mb-2"
                />
                {errors.street1 && touched.street1 ? (
                  <p style={{ color: "red" }}> {errors.street1} </p>
                ) : null}
              </Col>
              <Col md={6}>
                <Form.Label>Stree 2</Form.Label>
                <Form.Control
                  type="text"
                  name="street2"
                  autoComplete="off"
                  onChange={handleChange}
                  value={values.street2}
                  onBlur={handleBlur}
                  placeholder="Enter Street 2"
                  className="mb-5"
                />
                {/* {errors.street2 && touched.street2 ? (
                  <p style={{ color: "red" }}> {errors.street2} </p>
                ) : null} */}
              </Col>
              <Col md={6}>
                <Form.Label>Country <span style={{color: "red"}}>*</span></Form.Label>
                <Form.Control
                  type="text"
                  name="shippcountry"
                  autoComplete="off"
                  onChange={handleChange}
                  value={values.shippcountry}
                  onBlur={handleBlur}
                  placeholder="Enter Country Name"
                  className="mb-2"
                />
                {errors.shippcountry && touched.shippcountry ? (
                  <p style={{ color: "red" }}> {errors.shippcountry} </p>
                ) : null}
              </Col>
              <Col md={6}>
                <Form.Label>State <span style={{color: "red"}}>*</span></Form.Label>
                <Form.Control
                  type="text"
                  name="shippState"
                  autoComplete="off"
                  onChange={handleChange}
                  value={values.shippState}
                  onBlur={handleBlur}
                  placeholder="Enter State Name"
                  className="mb-2"
                />
                {errors.shippState && touched.shippState ? (
                  <p style={{ color: "red" }}> {errors.shippState} </p>
                ) : null}
              </Col>
              <Col md={6}>
                <Form.Label>City <span style={{color: "red"}}>*</span></Form.Label>
                <Form.Control
                  type="text"
                  name="shippCity"
                  autoComplete="off"
                  onChange={handleChange}
                  value={values.shippCity}
                  onBlur={handleBlur}
                  placeholder="Enter City Name"
                  className="mb-2"
                />
                {errors.shippCity && touched.shippCity ? (
                  <p style={{ color: "red" }}> {errors.shippCity} </p>
                ) : null}
              </Col>
              <Col md={6}>
                <Form.Label>Zip Code <span style={{color: "red"}}>*</span></Form.Label>
                <Form.Control
                  type="number"
                  name="shippZipCode"
                  autoComplete="off"
                  onChange={handleChange}
                  value={values.shippZipCode}
                  onBlur={handleBlur}
                  placeholder="Enter Zip Code"
                  className="mb-2"
                />
                {errors.shippZipCode && touched.shippZipCode ? (
                  <p style={{ color: "red" }}> {errors.shippZipCode} </p>
                ) : null}
              </Col>
              <Col md={6}>
                <Form.Label>Website URL <span style={{color: "red"}}>*</span></Form.Label>
                <Form.Control
                  type="text"
                  name="websiteUrl"
                  autoComplete="off"
                  onChange={handleChange}
                  value={values.websiteUrl}
                  onBlur={handleBlur}
                  placeholder="Enter Web URL"
                  className="mb-2"
                />
                {errors.websiteUrl && touched.websiteUrl ? (
                  <p style={{ color: "red" }}> {errors.websiteUrl} </p>
                ) : null}
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              handleCloseModal();
              resetForm();
            }}
          >
            Close
          </Button>
          <Button
            style={{
              backgroundColor: "#FACD21",
              border: "none",
              color: "black",
            }}
            type="submit"
            onClick={() => {
              handleSubmit();
            }}
          >
            Create order
          </Button>
        </Modal.Footer>
      </Modal >
    </>
  );
}
