import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import TablePagination from "../../TablePagination";
import {
  addAdmin,
  deleteAdmin,
  getUserList,
  updateUser,
} from "../../Config/ConfigServices";
import { useEffect } from "react";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { Button, Modal } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { addAdminData } from "../../../../redux/User/action";
import { showErrorToast, showSuccessToast } from "../ToastMsg";
import Cookies from "js-cookie";

const SignUpSchema = Yup.object({
  userId: Yup.string(),
  firstName: Yup.string()
    .trim()
    .required("First name is a required field"),
  lastName: Yup.string()
    .trim()
    .required("Last Name is a required field"),
  email: Yup.string().when("userId", {
    is: (userId) => !userId,
    then: Yup.string()
      .required("Password is a required field")
      .trim()
      .email(),
    otherwise: Yup.string().nullable(),
  }),
  phoneNumber: Yup.string()
    .trim()
    .min(10, "phone no. at least 10 characters")
    .matches(/^[0-9]+$/, 'Phone number must only contain digits')
    .required("Contact Number is required field"),
  password: Yup.string().when("userId", {
    is: (userId) => !userId,
    then: Yup.string()
      .required("Password is a required field")
      .trim()
      .min(6, "password must be at least 6 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z])/,
        'Password must have at least one lowercase letter, one uppercase letter, and one non-alphanumeric character'
      ),
    otherwise: Yup.string().nullable(),
  }),
  confirmPassword: Yup.string().when("userId", {
    is: (userId) => !userId,
    then: Yup.string()
      .required("Password is a required field")
      .trim()
      .min(6, "password must be at least 6 characters")
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    otherwise: Yup.string().nullable(),
  }),
});

const initialValues = {
  userId: "",
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  password: "",
  confirmPassword: "",
};

export default function AdminUser() {
  Cookies.remove("filterText");
  const [deleteShow, setDeleteShow] = useState(false);
  const handleDeleteShow = () => setDeleteShow(true);
  const handleDeleteClose = () => setDeleteShow(false);
  const [isEdit, setIsEdit] = useState(false);
  const [userId, setUserId] = useState("");
  const dispatch = useDispatch();

  const [editValue, setEditValue] = useState("");
  const [isModify, setModify] = useState(false);
  const [show, setShow] = useState(false);
  const d = new Date();
  let seconds = d.getSeconds();
  const handleOpenModal = (obj) => {
    if (obj) {
      setFieldValue("firstName", obj.firstName || "");
      setFieldValue("userId", obj.id || "");
      setFieldValue("email", obj.email || "");
      setFieldValue("lastName", obj.lastName || "");
      setFieldValue("phoneNumber", obj.phoneNumber || "");
    }
    setShow(true);
  };
  const handleCloseModal = () => {
    setShow(false);
    resetForm();
  };

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    errors,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: SignUpSchema,
    onSubmit: (values, action) => {
      if (values.userId) {
        updateUser(values)
          .then((res) => {
            return res.json();
          })
          .then((res) => {
            dispatch(addAdminData(values));
            showSuccessToast(res.message);
          });
      } else {
        addAdmin(values)
          .then((res) => {
            return res.json();
          })
          .then((res) => {
            dispatch(addAdminData(values));
            showSuccessToast(res?.message);
          })
          .catch((error) => {
            showErrorToast(error.message);
          });
      }

      handleCloseModal();
      action.resetForm();
    },
  });

  useEffect(() => {
    getUserList()
      .then((res) => res.json())
      .then((res) => {
        console.log(res.result);
      })
      .catch((err) => console.log(err));
  }, []);

  const renderActions = (_, row) => {
    return (
      <>
        <a
          href="#"
          title="Edit category"
          className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
          onClick={(e) => {
            e.stopPropagation();
            setIsEdit(true);
            handleOpenModal(row);
            setEditValue(row);
            setModify(true);
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
            />
          </span>
        </a>
        <a
          href="#"
          title="Delete User"
          className="btn btn-icon btn-light btn-hover-danger btn-sm mr-2"
          onClick={(e) => {
            e.stopPropagation();
            setUserId(row.id);
            handleDeleteShow();
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </a>
      </>
    );
  };

  const columns = [
    {
      dataField: "id",
      text: "ID",
      hidden: true,
    },
    {
      dataField: "firstName",
      text: "First Name",
      headerSortingClasses: "text-center pr-3",
    },
    {
      dataField: "lastName",
      text: "Last Name",
      headerSortingClasses: "text-center pr-3",
    },
    {
      dataField: "email",
      text: "Email ID",
      classes: "text-center pr-2",
      headerClasses: "text-center pr-3",
    },
    {
      dataField: "phoneNumber",
      text: "Contact Number",
      classes: "text-center pr-2",
      headerClasses: "text-center pr-3",
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: renderActions,
      classes: "text-center pr-2",
      headerClasses: "text-center pr-3",
    },
  ];
  const handleDelete = () => {
    deleteAdmin(userId)
      .then((res) => res.json())
      .then((res) => {
        dispatch(addAdminData(seconds));
        showSuccessToast(res?.message);
        handleDeleteClose();
      })
      .catch((error) => console.log(error));
  };

  return (
    <>
      <Card>
        <CardHeader title="Admin Users">
          <CardHeaderToolbar>
            <Button
              onClick={() => {
                handleOpenModal(null);
                setIsEdit(false);
              }}
            >
              Add Admin User
            </Button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody style={{ justifyContent: "center" }}>
          <TablePagination
            keyField="Id"
            columns={columns}
            getRecordList={getUserList}
            isShowExportCsv={false}
          />
        </CardBody>
      </Card>
      <Modal show={show} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <div className="d-flex">
            <Modal.Title>{isEdit ? "Edit User" : "Add User"}</Modal.Title>
          </div>
        </Modal.Header>
        <form className="needs-validation" onSubmit={handleSubmit}>
          <Modal.Body>
            <>
              <label for="fName">First Name</label>
              <input
                type="text"
                name="firstName"
                className="form-control mb-3"
                placeholder="First Name"
                onChange={handleChange}
                value={values.firstName}
                onBlur={handleBlur}
              />
              {errors.firstName && touched.firstName ? (
                <p style={{ color: "red" }}> {errors.firstName} </p>
              ) : null}
              <label for="lName">Last Name</label>
              <input
                type="text"
                name="lastName"
                className="form-control mb-3"
                placeholder="Last Name"
                onChange={handleChange}
                value={values.lastName}
                onBlur={handleBlur}
              />
              {errors.lastName && touched.lastName ? (
                <p style={{ color: "red" }}> {errors.lastName} </p>
              ) : null}
              <label for="contact">Contact Number</label>
              <input
                type="text"
                name="phoneNumber"
                className="form-control mb-3"
                placeholder="Contact Number"
                onChange={handleChange}
                value={values.phoneNumber}
                onBlur={handleBlur}
              />
              {errors.phoneNumber && touched.phoneNumber ? (
                <p style={{ color: "red" }}> {errors.phoneNumber} </p>
              ) : null}
              {!isEdit && (
                <>
                  <label for="email">Email</label>
                  <input
                    type="text"
                    name="email"
                    className="form-control mb-3"
                    placeholder="Email Id"
                    onChange={handleChange}
                    value={values.email}
                    onBlur={handleBlur}
                  />
                  {errors.email && touched.email ? (
                    <p style={{ color: "red" }}> {errors.email} </p>
                  ) : null}
                  <label for="pass">Password</label>
                  <input
                    type="text"
                    name="password"
                    className="form-control mb-3"
                    placeholder="Password"
                    onChange={handleChange}
                    value={values.password}
                    onBlur={handleBlur}
                  />
                  {errors.password && touched.password ? (
                    <p style={{ color: "red" }}> {errors.password} </p>
                  ) : null}
                  <label for="cPass">Confirm Password</label>
                  <input
                    type="text"
                    name="confirmPassword"
                    className="form-control mb-3"
                    placeholder="Confirm Password"
                    onChange={handleChange}
                    value={values.confirmPassword}
                    onBlur={handleBlur}
                  />
                  {errors.confirmPassword && touched.confirmPassword ? (
                    <p style={{ color: "red" }}> {errors.confirmPassword} </p>
                  ) : null}
                </>
              )}
            </>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn btn-warning" type="submit">
              Save
            </Button>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

      <Modal show={deleteShow} onHide={handleDeleteClose} centered>
        <Modal.Header>
          <Modal.Title>Delete User</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure to delete this User?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDeleteClose}>
            Close
          </Button>
          <Button
            variant="primary btn btn-danger"
            onClick={() => {
              handleDelete();
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
