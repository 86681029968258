import Cookies from "js-cookie";
{/* Start of Order List related API */}
export async function getActiveOrderList() {
  let authToken = localStorage.getItem("authToken");
  const orderStatus = Cookies.get("filterText");
  let url = process.env.REACT_APP_API_URL.replace('/api', '')
  const reqoption = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  };
  if(orderStatus){
    return await fetch(
      `${url}/order/orders-list?orderStatus=${orderStatus}`,
      reqoption
    );
  }else{
    return await fetch(
      `${url}/order/orders-list`,
      reqoption
    );
  }
}

export async function updateOrderStatus(
  orderNumber,
  orderStatus,
  trimRemarkVal,
  trackingValue,
  shipValue
) {
  let authToken = localStorage.getItem("authToken");
  let order = {
    orderNumber: orderNumber,
    orderStatus: orderStatus,
    trackingNumber: trackingValue,
    shippingMethod: parseInt(shipValue),
    remarks: trimRemarkVal,
  };
  let url = process.env.REACT_APP_API_URL.replace('/api', '')
  const reqoption = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify(order),
  };
  return await fetch(
    `${url}/order/update-order-status`,
    reqoption
  );
}

{/* End of Order List related API */}

{/* Start of Admin User related API */}

export async function getUserList() {
  let authToken = localStorage.getItem("authToken");
  const reqoption = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  };
  return await fetch(
    `${process.env.REACT_APP_API_URL}/user/users-list`,
    reqoption
  );
}

export function addAdmin(obj) {
  let authToken = localStorage.getItem("authToken");
  const reqoption = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authToken,
    },
    body: JSON.stringify(obj),
  };
  return fetch(`${process.env.REACT_APP_API_URL}/user/add-user`, reqoption);
}

export function deleteAdmin(userId) {
  let authToken = localStorage.getItem("authToken");
  const reqoption = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authToken,
    },
  };
  return fetch(`${process.env.REACT_APP_API_URL}/user/${userId}`,reqoption);
}

export function updateUser(obj) {
  let authToken = localStorage.getItem("authToken");
  const reqoption = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authToken,
    },
    body: JSON.stringify(obj)
  };
  return fetch(`${process.env.REACT_APP_API_URL}/user/user-edit`,reqoption);
}

export function createOrder(obj) {
  let url = process.env.REACT_APP_API_URL.replace('/api', '')
  let authToken = localStorage.getItem("authToken");
  const reqoption = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authToken,
    },
    body: JSON.stringify(obj),
  };
  
  return fetch(`${url}/order/add-order`, reqoption);
}
