export const charValidate = (text, limit) => {
    if (text !== undefined && limit !== undefined) {
        if (text !== null) {
            if (text.length > limit) {
                return `${text.substring(0, limit - 2)}..`;
            } else {
                return `${text.substring(0, limit)}`;
            }
        } else {
            return '';
        }
    }
};