import React from "react";
import { useState } from "react";
import { Button, Carousel, Form, Modal, Table, Col, Row } from "react-bootstrap";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { useHistory, useLocation } from "react-router-dom";
import { updateOrderStatus } from "../Config/ConfigServices";
import { showErrorToast, showSuccessToast } from "./ToastMsg";
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

export default function ItemsDetails() {
  const history = useHistory();
  const location = useLocation();
  let orderDetails = location.state;
  let orderRemars = orderDetails?.remarks
  const arrayData = location?.state?.item || null;
  const shippingAddress = location?.state?.shippingAddress || [];
  const remarkData = location?.state.remarks || null;
  let backImages = arrayData[0].imagesModel?.backImages;
  let orderNumber = location.state.orderNumber;
  const [backImg, setBackImg] = useState([]);
  const [showImg, setShowImg] = useState(false);
  const [checkState, setCheckState] = useState("");
  const handleImgClose = () => setShowImg(false);
  const handleImgShow = () => setShowImg(true);
  const [show, setShow] = useState(false);
  const [showAddress, setShowAddress] = useState(false);
  const [remarkValue, setRemarkValue] = useState("");
  const [isValidate, setIsvalidate] = useState("");
  const [shipValue, setShipValue] = useState(1);
  const [trackingValue, setTrackingValue] = useState("");
  const [rejectedBtn, setRejectBtn] = useState(false);
  const handleOpenModal = () => {
    setShow(true);
  };
  const handleCloseModal = () => {
    setShow(false);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let trimRemarkVal = remarkValue.replace(/\s+/g, " ");
    let orderStatus = 0;
    if (checkState === "Accept") {
      orderStatus = 1;
    } else if (checkState === "Reject") {
      orderStatus = 3;
    } else if (checkState === "Shipped") {
      orderStatus = 4;
    }

    updateOrderStatus(
      orderNumber,
      orderStatus,
      trimRemarkVal,
      trackingValue,
      shipValue
    )
      .then((res) => res.json())
      .then((res) => {
        showSuccessToast(res?.message);
        history.push("/all_orders");
        setRemarkValue("");
        handleCloseModal();
      })
      .catch((error) => {
        showErrorToast(error.message);
        console.log(error);
      });
  };

  const handleDownloadImages = () => {
    const zip = new JSZip();
    const imagePromises = [];
    // Loop through the image URLs and add them to the zip file
    backImages.forEach((imageUrl, index) => {
      const fileName = `image_${index + 1}.jpg`;
      const imagePromise = fetch(imageUrl)
        .then((response) => response.blob())
        .then((blob) => zip.file(fileName, blob));

      imagePromises.push(imagePromise);
    });

    Promise.all(imagePromises)
      .then(() => {
        zip.generateAsync({ type: 'blob' })
          .then((content) => {
            saveAs(content, 'images.zip');
          });
      })
      .catch((error) => {
        console.error('Error downloading images:', error);
        showErrorToast("Error downloading images")
      });
  };

  return (
    <>
      <Card>
        <CardHeader>
          <div className="d-flex">
            <Button
              variant="light"
              onClick={() => history.goBack()}
              style={{ position: "absolute", left: "1rem", top: "1rem" }}
            >
              <i className="fa fa-arrow-left"></i>
            </Button>
            <h3
              style={{
                "font-weight": 500,
                "font-size": "1.275rem",
                color: "#181C32",
                position: "absolute",
                top: "1.6rem",
                left: "6rem",
              }}
            >
              Order Details
            </h3>
          </div>
          <CardHeaderToolbar>
            {orderDetails.orderStatus === "Pending" && (
              <>
                {orderRemars &&
                  <p className="text-danger h6 mr-2 mt-2" style={{ color: "#f44336", backgroundColor: "#ffebee", padding: "10px", borderRadius: "5px", fontSize: "16px", fontWeight: "bold" }}>your order rejected previously</p>
                }
                <h3 className="mr-4"> Actions : </h3>
                <span
                  onClick={() => {
                    handleOpenModal();
                    setCheckState("Accept");
                    setRejectBtn(false)
                  }}
                  title="Approve"
                >
                  <Button className="btn btn-success">Approve</Button>
                </span>
                <span
                  onClick={() => {
                    handleOpenModal();
                    setCheckState("Reject");
                  }}
                  className="ml-2"
                  title="Reject"
                >
                  <Button
                    className="btn btn-danger"
                    onClick={() => setRejectBtn(true)}
                  >
                    Reject
                  </Button>
                </span>
              </>
            )}
            {orderDetails.orderStatus === "Accept" ? (
              <>
                <h3 className="mr-4"> Actions : </h3>
                <Button className="btn btn-outline-info">
                  <h6
                    onClick={() => {
                      setCheckState("Shipped");
                      handleOpenModal();
                    }}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    Ready to ship
                    <i className="fas fa-shipping-fast ml-1"></i>
                  </h6>
                </Button>
              </>
            ) : (
              ""
            )}
            {orderDetails.orderStatus === "Rejected" ? (
              <>
                <p className="text-danger h6 mr-2 mt-2" style={{ color: "#f44336", backgroundColor: "#ffebee", padding: "10px", borderRadius: "5px", fontSize: "16px", fontWeight: "bold" }}>your order rejected previously</p>
                <span
                  onClick={() => {
                    handleOpenModal();
                    setCheckState("Accept");
                    setRejectBtn(false)
                  }}
                  title="Approve"
                >
                  <Button className="btn btn-success">Approve</Button>
                </span>
                <span
                  onClick={() => {
                    handleOpenModal();
                    setCheckState("Reject");
                  }}
                  className="ml-2"
                  title="Reject"
                >
                  <Button
                    className="btn btn-danger"
                    onClick={() => setRejectBtn(true)}
                  >
                    Reject
                  </Button>
                </span>
              </>
            ) : (
              ""
            )}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody style={{ justifyContent: "center" }}>
          <Table bordered responsive>
            <thead>
              <tr className="text-center">
                <th>SKU</th>
                <th>Part Number</th>
                <th>Description</th>
                <th>Quantity</th>
                {orderDetails.orderStatus === "Rejected" && <th>Reason</th>}
                <th>Information</th>
                <th>images</th>
              </tr>
            </thead>
            <tbody>
              {arrayData.map((items) => {
                return (
                  <>
                    <tr className="text-center">
                      <td>{items?.skuId || "----"}</td>
                      <td>{items?.partNumber || "----"}</td>
                      <td>{items?.description || "----"}</td>
                      <td>{items?.totalQty || "----"}</td>
                      {orderDetails.orderStatus === "Rejected" && <td>{remarkData}</td>}
                      <td onClick={() => setShowAddress(true)}>
                        <span className="svg-icon svg-icon-lg svg-icon-primary cursor-pointer">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#419FFF" class="bi bi-eye-fill" viewBox="0 0 16 16">
                            <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                            <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                          </svg>
                        </span>
                      </td>
                      <td
                        className="text-center"
                      >
                        {/* <p>
                          <u
                            className="mr-2 cursor-pointer"
                            onClick={() => {
                              setBackImg([...items?.imagesModel?.backImages]);
                              handleImgShow();
                            }}>
                            View
                          </u>
                          {/* <u className="cursor-pointer" onClick={handleDownloadImages}>Download</u> */}
                        {/* </p> */}

                        <span className="svg-icon svg-icon-lg svg-icon-primary cursor-pointer" onClick={() => {
                          setBackImg([...items?.imagesModel?.backImages]);
                          handleImgShow();
                        }}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#419FFF" class="bi bi-images" viewBox="0 0 16 16">
                            <path d="M4.502 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                            <path d="M14.002 13a2 2 0 0 1-2 2h-10a2 2 0 0 1-2-2V5A2 2 0 0 1 2 3a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v8a2 2 0 0 1-1.998 2zM14 2H4a1 1 0 0 0-1 1h9.002a2 2 0 0 1 2 2v7A1 1 0 0 0 15 11V3a1 1 0 0 0-1-1zM2.002 4a1 1 0 0 0-1 1v8l2.646-2.354a.5.5 0 0 1 .63-.062l2.66 1.773 3.71-3.71a.5.5 0 0 1 .577-.094l1.777 1.947V5a1 1 0 0 0-1-1h-10z" />
                          </svg>
                        </span>
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </Table>
        </CardBody>
      </Card>
      <Modal show={show} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <div className="d-flex">
            <Modal.Title>
              {checkState === "Accept" && "Approve Order"}
              {checkState === "Reject" && "Reject Order"}
              {checkState === "Shipped" && "Shipped Order"}
            </Modal.Title>
          </div>
        </Modal.Header>
        <form className="needs-validation" onSubmit={handleSubmit}>
          <Modal.Body>
            {checkState === "Accept" &&
              "Are you sure you want to Approve this order ?"}
            {checkState === "Reject" &&
              "Are you sure you want to Reject this order ?"}
            {checkState === "Shipped" && null}
            {checkState === "Reject" && (
              <>
                <input
                  type="Remark"
                  className="form-control mt-4"
                  // id="exampleInputEmail1"
                  // aria-describedby="emailHelp"
                  placeholder="Enter Remarks"
                  onChange={(e) => {
                    setRemarkValue(e.target.value);
                    setIsvalidate(false);
                  }}
                  value={remarkValue}
                  required
                />
                {isValidate && (
                  <p style={{ color: "red" }}>Remark is required</p>
                )}
              </>
            )}
            {checkState === "Shipped" && (
              <>
                <label for="shippingMethod">Choose Shipping Method:</label>
                <select
                  name="shippingMethod"
                  id="shippingMethod"
                  onChange={(e) => {
                    let shipmethod = e.target.value;
                    setShipValue(shipmethod);
                  }}
                  className="ml-2"
                >
                  <option value="1">Expedite</option>
                  <option value="2">Expedited</option>
                  <option value="3">Overnight</option>
                  <option value="4">PriorityMail</option>
                  <option value="5">Flatrate</option>
                  <option value="6">Multipleaddresses</option>
                  <option value="7">Localdeliveryorpickup</option>
                  <option value="8">International</option>
                  <option value="9">Freight</option>
                </select>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Tracking Id"
                  onChange={(e) => {
                    let trackingId = e.target.value;
                    setTrackingValue(trackingId);
                  }}
                  value={trackingValue}
                  required
                />
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            {rejectedBtn ? (
              <Button className="btn btn-danger" type="submit">
                Reject
              </Button>
            ) : (
              <Button className="btn btn-warning" type="submit">
                Save
              </Button>
            )}
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

      <Modal show={showImg} onHide={handleImgClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Expanded Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Carousel>
            {backImg.map((image, index) => (
              <Carousel.Item key={index} interval={500}>
                <img className="d-block w-100" src={image} alt="back image" />
              </Carousel.Item>
            ))}
          </Carousel>
          <i
            style={{
              position: "absolute",
              top: "-55px",
              right: "4px",
              color: "black",
              "font-size": "2rem",
              cursor: "pointer",
            }}
            onClick={() => handleImgClose()}
            class="fa fa-times-circle"
            aria-hidden="true"
          ></i>
        </Modal.Body>
      </Modal>
      <Modal
        show={showAddress}
        onHide={() => setShowAddress(false)}
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>View Order Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <h6 className="pb-5">Item</h6>
            <Row>
              {arrayData.map((items) => {
                return (
                  <>
                    <Col md={6}>
                      <Form.Label>SKU</Form.Label>
                      <Form.Control
                        type="text"
                        name="sku"
                        autoComplete="off"
                        value={items?.skuId || "----"}
                        placeholder="Enter SKU Id"
                        className="mb-2"
                        disabled
                      />
                    </Col>
                    <Col md={6}>
                      <Form.Label>Quantity</Form.Label>
                      <Form.Control
                        type="number"
                        name="quantity"
                        autoComplete="off"
                        value={items?.totalQty || "----"}
                        placeholder="Enter Quantity"
                        className="mb-2"
                        disabled
                      />
                    </Col>
                    <Col md={6}>
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        type="text"
                        name="description"
                        autoComplete="off"
                        value={items?.description || "----"}
                        placeholder="Enter Description"
                        className="mb-2"
                        disabled
                      />
                    </Col>
                    <Col md={6}>
                      <Form.Label>Website</Form.Label>
                      <Form.Control
                        type="text"
                        name="description"
                        autoComplete="off"
                        value={orderDetails?.websiteUrl || "----"}
                        placeholder="Enter Description"
                        className="mb-2"
                        disabled
                      />
                    </Col>
                  </>
                )
              })}
            </Row>
            <hr />
            <h6 className="pb-5">Shipping</h6>
            <Row>
              <Col md={6}>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="shippName"
                  autoComplete="off"
                  value={shippingAddress?.name || "----"}
                  placeholder="Enter Shipping Name"
                  className="mb-2"
                  disabled
                />
              </Col>
              <Col md={6}>
                <Form.Label>Street 1</Form.Label>
                <Form.Control
                  type="text"
                  name="street1"
                  autoComplete="off"
                  value={shippingAddress?.street1 || "----"}
                  placeholder="Enter Street 1"
                  className="mb-2"
                  disabled
                />
              </Col>
              <Col md={6}>
                <Form.Label>Stree 2</Form.Label>
                <Form.Control
                  type="text"
                  name="street2"
                  autoComplete="off"
                  value={shippingAddress?.street2 || "----"}
                  placeholder="Enter Street 2"
                  className="mb-5"
                  disabled
                />
              </Col>
              <Col md={6}>
                <Form.Label>Country</Form.Label>
                <Form.Control
                  type="text"
                  name="shippcountry"
                  autoComplete="off"
                  value={shippingAddress?.country || "----"}
                  placeholder="Enter Country Name"
                  className="mb-2"
                  disabled
                />
              </Col>
              <Col md={6}>
                <Form.Label>State</Form.Label>
                <Form.Control
                  type="text"
                  name="shippState"
                  autoComplete="off"
                  value={shippingAddress?.state || "----"}
                  placeholder="Enter State Name"
                  className="mb-2"
                  disabled
                />
              </Col>
              <Col md={6}>
                <Form.Label>City</Form.Label>
                <Form.Control
                  type="text"
                  name="shippCity"
                  autoComplete="off"
                  value={shippingAddress?.city || "----"}
                  placeholder="Enter City Name"
                  className="mb-2"
                  disabled
                />
              </Col>
              <Col md={6}>
                <Form.Label>Zip Code</Form.Label>
                <Form.Control
                  //type="number"
                  name="shippZipCode"
                  autoComplete="off"
                  value={shippingAddress?.zipCode || "----"}
                  placeholder="Enter Zip Code"
                  className="mb-2"
                  disabled
                />
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowAddress(false);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal >
    </>
  );
}
